import { Row, Col, Card, Typography, Form, Input, Button, Select } from "antd";
import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import Loader from "../components/loader";
import { useLocation, useHistory } from "react-router-dom";
import { userContext } from "../context/UserContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileExcelOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Swal from "sweetalert2";

const Deposits = () => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [bulkloading, setBulkLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [provider, setProvider] = useState("");

  const [file, setFile] = useState();
  const location = useLocation();
  const history = useHistory();
  const { Option } = Select;
  const { setPerson, person, updateCgrate } = useContext(userContext);
  const token = localStorage.getItem("access_token");
  const { Dragger } = Upload;
  const [clients, setClients] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState("");
  const [operator, setOperator] = useState("");
  const [phoneInput, setPhoneInput] = useState(false);

  const handleClientChange = (value) => {
    if (value === "other") {
      setPhoneInput(true);
    } else {
      const client = clients.find(
        (client) => `${client.first_name} ${client.last_name}` === value
      );

      setPhoneInput(false);
      setSelectedPhone(client.phone); // Set the phone number based on the selected client
      operatorFetch(client.phone);
    }
  };
  const operatorFetch = (phone) => {
    // Extract the first three digits from the phone number
    const prefix = phone.slice(0, 3);

    // Determine the operator based on the prefix
    if (["097", "077"].includes(prefix)) {
      setOperator("Airtel");
    } else if (["096", "076"].includes(prefix)) {
      setOperator("MTN");
    } else if (["095"].includes(prefix)) {
      setOperator("Zamtel");
    } else {
      setOperator("Unknown Operator"); // Fallback for unknown prefixes
    }
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");

      if (!isCSV) {
        message.error("You can only upload CSV files!");
      }

      return isCSV;
    },
    customRequest(info) {
      info.onSuccess();

      setFile(info.file);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        //message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target; // Extract value from the event

    setSelectedPhone(value); // Update the selected phone number state
    operatorFetch(value);
  };

  const handleProviderChange = (value) => {
    setProvider(value);
  };

  const handleAmountChange = (value) => {
    setAmount(value);
  };

  const onFileUploaded = async (values) => {
    const fileContent = await readUploadedFile(file);

    // Parse CSV and calculate sum
    const amounts = parseCSVAndSum(fileContent);
    const accounts = parseCSVAndCountRows(fileContent);

    Swal.fire({
      title: "Bulk Deposit",
      text: `Deposit K${amounts} total for ${accounts} accounts?`,
      icon: "info",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onBulkFinish(values, amounts, accounts);
      }
    });
  };

  const parseCSVAndSum = (csvContent) => {
    const rows = csvContent.split("\n");
    let sum = 0;

    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split(",");
      const amount = parseFloat(columns[2]);

      if (!isNaN(amount)) {
        sum += amount;
      }
    }

    return sum;
  };

  const parseCSVAndCountRows = (csvContent) => {
    const rows = csvContent.split("\n");
    let rowCount = 0;

    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split(",");

      // Check if the row has valid data, adjust this condition based on your CSV structure
      if (columns.length > 1 && columns[1].trim() !== "") {
        rowCount++;
      }
    }

    return rowCount;
  };

  const readUploadedFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const onBulkFinish = async (values, amounts, accounts) => {
    if (parseFloat(amounts) > parseFloat(person.balance) - 5) {
      toast.error("Insufficient Funds", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      setBulkLoading(true);

      const formData = new FormData();

      const deductions = accounts * 5;

      const adminBalance =
        parseFloat(person.balance) - parseFloat(amounts) - deductions;

      formData.append("myfile", file);
      formData.append("companyname", person.companyname);
      formData.append("token", token);
      formData.append("balance_id", person.balance_id);
      formData.append("balance", person.balance);
      formData.append("newbalance", adminBalance);

      axios
        .post(
          `${process.env.REACT_APP_SERVER}/operation/bulkdeposit`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          setBulkLoading(false);

          Swal.fire({
            title: response.data.message,
            text: `${response.data.processedCount} rows processed. Total : K${response.data.totalAmount}`,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              setPerson({
                ...person,
                balance: adminBalance,
              });
              updateCgrate();
              history.push("/dashboard");
            }
          });
        })
        .catch((error) => {
          setBulkLoading(false);
          toast.error("Invalid Credentials", {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    }
  };

  const onBeforeFinish = async (values) => {
    Swal.fire({
      title: "Deposit",
      text: `Send K${values.amount} to ${selectedPhone}?`,
      icon: "info",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onFinish(values);
      }
    });
  };

  const onFinish = async (values) => {
    if (parseFloat(values.amount) > parseFloat(person.balance) - 5) {
      toast.error("Insufficient Funds", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      try {
        setLoading(true);

        const newbalance =
          parseFloat(person.balance) - parseFloat(values.amount) - 5;

        const postData = {
          provider: operator,
          phone: selectedPhone,
          amount: values.amount,
          companyname: person.companyname,
          token: token,
          balance_id: person.balance_id,
          balance: person.balance,
          newbalance: newbalance,
          vtype: "Direct-Topup",
        };

        await axios
          .post(`${process.env.REACT_APP_SERVER}/operation/deposit`, postData)
          .then((response) => {
            form.resetFields();
            setLoading(false);
            setPerson({
              ...person,
              balance: newbalance,
            });
            updateCgrate();

            Swal.fire({
              title: `Deposit`,
              text: `Deposit was successful`,
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/dashboard");
              }
            });
          });
      } catch (error) {
        setLoading(false);
        toast.error("Someting went wrong", {
          position: toast.POSITION.TOP_LEFT,
        });
        // console.error("Error submitting form:", error);
        // Handle error cases
      }
    }
  };

  useEffect(() => {
    async function fetchClients() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ADMIN_URL}/users?fields=id,first_name,last_name,phone`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setClients(data.data); // Assuming response.data.data contains the user list
        setLoading(false);
      } catch (error) {
        console.error("Error fetching client data", error);
        setLoading(false);
      }
    }

    fetchClients();
  }, []);

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Single Deposit</Title>
            <Form name="myForm" onFinish={onBeforeFinish} form={form}>
              <Form.Item
                className="username"
                label="Client Name"
                name="client_name"
                rules={[
                  {
                    required: true,
                    message: "Please select a client",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Client"
                  onChange={handleClientChange}
                  loading={loading}
                  optionFilterProp="value" // Filter based on the 'value' prop of Option
                  filterOption={
                    (input, option) =>
                      option?.value.toLowerCase().includes(input.toLowerCase()) // Make sure 'value' is used for comparison
                  }
                >
                  {clients.map((client) => (
                    <Option
                      key={client.id}
                      value={`${client.first_name} ${client.last_name}`} // Using the full name in value
                    >
                      {client.first_name} {client.last_name}
                    </Option>
                  ))}
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>

              {phoneInput ? (
                <Form.Item
                  className="username"
                  label="Phone Number"
                  name="phone"
                  initialValue={
                    location.state && location.state.phone
                      ? location.state.phone
                      : ""
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Phone starting with 0"
                    onChange={handlePhoneChange}
                  />
                </Form.Item>
              ) : (
                <></>
              )}

              <Form.Item
                className="username"
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter amount",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter amount",
                    },
                  ]}
                  type="number"
                  placeholder="Amount"
                  onChange={handleAmountChange}
                />
              </Form.Item>

              <Loader loading={loading} />
              <Text
                type="secondary"
                style={{ marginBottom: "16px", display: "block" }}
              >
                * Note, there is an additional K5 charge for each deposit
              </Text>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Bulk Deposit</Title>
            <Form name="myForm" onFinish={onFileUploaded}>
              <Form.Item className="username" label="Upload File">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <FileExcelOutlined style={{ color: "green" }} />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">File must be in CSV Format</p>
                </Dragger>
              </Form.Item>
              <Loader loading={bulkloading} />
              <Text
                type="secondary"
                style={{ marginBottom: "16px", display: "block" }}
              >
                {" "}
                <Text
                  type="secondary"
                  style={{ marginBottom: "16px", display: "block" }}
                >
                  * Note, there is an additional K5 charge for each account
                  deposit
                </Text>
              </Text>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default Deposits;
