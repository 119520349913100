import { Row, Col, Card, Typography, Form, Input, Button } from "antd";
import React, { useState, useContext } from "react";
import Loader from "../components/loader";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { userContext } from "../context/UserContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileExcelOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Swal from "sweetalert2";

const Topup = () => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { setPerson, person, updateCgrate } = useContext(userContext);
  const token = localStorage.getItem("access_token");

  const onFinish = async (values) => {
    const percentage = 2.5;
    const charge = (percentage / 100) * parseFloat(values.amount);
    const newbalance =
      parseFloat(values.amount) - charge + parseFloat(person.balance);

    try {
      setLoading(true);

      const postData = {
        phone: values.phone,
        amount: values.amount,
        balance_id: person.balance_id,
        balance: person.balance,
        newbalance: newbalance,
        token: token,
      };

      await axios
        .post(`${process.env.REACT_APP_SERVER}/operation/payment`, postData)
        .then((response) => {
          form.resetFields();
          setLoading(false);

          setPerson({ ...person, balance: newbalance });
          updateCgrate();

          Swal.fire({
            title: "Topup",
            text: `Account Topup was successful`,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/dashboard");
            }
          });
        });
    } catch (error) {
      setLoading(false);
      toast.error("Someting went wrong", {
        position: toast.POSITION.TOP_LEFT,
      });

      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Topup your account</Title>
            <Form name="myForm" onFinish={onFinish} form={form}>
              <Form.Item
                className="username"
                label="Mobile Money Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                  ]}
                  type="number"
                  placeholder="Number starting with 0"
                />
              </Form.Item>

              <Form.Item
                className="username"
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter amount",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter amount",
                    },
                  ]}
                  type="number"
                  placeholder="Amount"
                />
              </Form.Item>
              <Text
                type="secondary"
                style={{ marginBottom: "16px", display: "block" }}
              >
                * Note, your account will be charged 2.5% of top-up value.
              </Text>

              <Loader loading={loading} />
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Topup;
