import {
  Row,
  Col,
  Card,
  Table,
  Input,
  Button,
  Avatar,
  Typography,
  Space,
} from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import React from "react";
import { useState, useEffect } from "react";
import { userContext } from "../context/UserContext";
import Loader from "../components/loader";
import { UserAddOutlined, UsergroupAddOutlined } from "@ant-design/icons";

const Clients = () => {
  const { Title } = Typography;

  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [clients, setClients] = useState([]);

  const getClients = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN_URL}/users?fields=*,balance.*`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      // console.log(response.data.data);

      setClients(response.data.data);
    } catch (error) {
      setLoading(false);

      console.log(error);

      if (error.response.status === 401) {
        history.push("/sign-in");
      }
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);

    const filtered = clients.filter((record) =>
      ["first_name", "last_name", "email", "companyname"].some(
        (field) =>
          record[field] &&
          record[field].toString().toLowerCase().includes(value.toLowerCase())
      )
    );

    setFilteredData(filtered);
  };

  const handleViewRecord = (debtor) => {
    // Navigate to another component and pass the record data
    history.push("/profile", { debtor });
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      width: "32%",
      render: (name, record) => (
        // Customize the rendering of the 'name' cell
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={`${process.env.REACT_APP_ADMIN_URL}/assets/${record.avatar}`}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>
              {record.first_name + " " + record.last_name}
            </Title>
            <p>{record.email}</p>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "BUSINESS NAME",
      dataIndex: "companyname",
      key: "ocmpanyname",
      render: (companyname, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{companyname}</Title>
          </div>
        </>
      ),
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
      render: (phone, record) => (
        <>
          <div className="author-info">
            <Title level={5}>{record.phone}</Title>
          </div>
        </>
      ),
    },

    {
      title: "BALANCE",
      key: "blanace",
      dataIndex: "balance",
      render: (balance, record) => (
        <>
          <Button
            type="secondary"
            className="tag-secondary"
            onClick={() => handleViewRecord(record)}
          >
            {"K" + parseFloat(record.balance[0].balance).toFixed(2)}
          </Button>
        </>
      ),
    },

    {
      title: "VIEW",
      key: "view",
      render: (text, record) => (
        <Button type="link" onClick={() => handleViewRecord(record)}>
          View
        </Button>
      ),
    },
  ];

  const addUser = () => {
    history.push(`/adduser`);
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <>
      <div className="tabled">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Clients"
                extra={
                  <Space>
                    <Button type="primary" onClick={addUser}>
                      <UsergroupAddOutlined
                        style={{ fontSize: "20px", color: "white" }}
                      />
                    </Button>
                    <Input.Search
                      placeholder="Search"
                      onChange={(e) => handleSearch(e.target.value)}
                      value={searchText}
                    />
                  </Space>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={searchText ? filteredData : clients}
                    className="ant-border-space"
                    pagination={{
                      pageSize: 5,
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Clients;
