import { useState, useEffect, useContext } from "react";
import { Row, Col, Card, Avatar, Radio, Switch, Typography } from "antd";
import BgProfile from "../assets/images/bg-profile.jpg";
import { useLocation } from "react-router-dom";
import Overview from "./overview";
import Contacts from "./contacts";
import Profileloans from "./profileloans";
import { toast, ToastContainer } from "react-toastify";
import React from "react";
import National from "./national";
import { userContext } from "../context/UserContext";
import Swal from "sweetalert2";
import axios from "axios";

const Profile = () => {
  const location = useLocation();
  const debtor = location.state.debtor;
  const [selectedTab, setSelectedTab] = useState("a"); // Initial tab
  const [stext, setStext] = useState(false);
  const { person, setPerson } = useContext(userContext);
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");

  const handleTabChange = (e) => {
    setSelectedTab(e.target.value);
  };

  const showToast = (message, type) => {
    if (type == "success") {
      toast.success(message, {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      toast.error(message, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const renderTabContent = () => {
    if (selectedTab === "a") {
      return <Overview client={debtor} showToast={showToast} />;
    }
  };

  const onChange = (checked) => {
    setStext(checked);

    let newstatus = "";
    if (checked) {
      newstatus = "active";
    } else {
      newstatus = "archived";
    }
    updateUser(newstatus);
  };

  const updateUser = async (newstatus) => {
    axios
      .patch(
        `${process.env.REACT_APP_ADMIN_URL}/users/${debtor.id}`,
        { status: newstatus },

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (person.id == debtor.id) {
          setPerson({ ...person, status: newstatus });
        }

        Swal.fire({
          title: "User status",
          text: "This user's status has been updated",
          icon: "success",
        });
      })
      .catch((error) => {
        // Handle the error
      });
  };

  useEffect(() => {
    if (debtor.status == "active") {
      setStext(true);
    } else {
      setStext(false);
    }
  }, [debtor.status]);

  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar
                  size={74}
                  shape="square"
                  src={`${process.env.REACT_APP_ADMIN_URL}/assets/${debtor.avatar}`}
                />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">
                    {`${debtor.first_name} ${debtor.last_name}`}
                  </h4>
                  <p>{debtor.email}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={9}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group value={selectedTab} onChange={handleTabChange}>
                <Radio.Button value="a">
                  {`Balance : k${parseFloat(debtor.balance[0].balance).toFixed(
                    2
                  )}`}
                </Radio.Button>
                {/* <Radio.Button value="b">LOANS</Radio.Button>
                <Radio.Button value="d">NRC/ID</Radio.Button>
                <Radio.Button value="c">USER CONTACTS</Radio.Button> */}
              </Radio.Group>
            </Col>

            {person.role == `${process.env.REACT_APP_CLIENT_ROLE}` ? (
              <Col
                span={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              ></Col>
            ) : (
              <Col
                span={24}
                md={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <p style={{ marginRight: 8 }}>
                  {stext ? "Active" : "Archived"}
                </p>
                <Switch checked={stext} onChange={onChange} />
              </Col>
            )}
          </Row>
        }
      ></Card>
      {renderTabContent()}
      <ToastContainer />
    </>
  );
};

export default Profile;
