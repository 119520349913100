import React, { useContext, useState } from "react";
import logo from "../assets/images/paysmart.png";
import { userContext } from "../context/UserContext";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import QRCode from "qrcode.react";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";

const Signin = () => {
  const { updateUser, person } = useContext(userContext);

  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(true);
  const history = useHistory();

  const onFinish = (values) => {
    updateUser(values);
    setLogin(false);
  };

  const onOtpFinish = (values) => {
    if (values.otp == person.otp) {
      history.push("/dashboard");
    } else {
      toast.error("Incorrect OTP", {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const onFinishFailed = () => {};

  const handleGenerateOTPClick = () => {
    history.push("/otp");
  };

  const backToLogin = () => {
    setLogin(true);
  };

  const { Title } = Typography;
  const { Header, Content } = Layout;

  return (
    <>
      <Layout
        className="layout-default layout-signin"
        style={{ backgroundColor: "white" }}
      >
        <Content className="signin">
          <Row gutter={[24, 0]} justify="center" style={{ marginTop: "200px" }}>
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title
                level={4}
                style={{ textAlign: "center", marginBottom: "34px" }}
              >
                Uptake Resources
              </Title>

              {login ? (
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input type="email" placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input type="password" placeholder="Password" />
                  </Form.Item>

                  {/* <Form.Item className="otp" label="OTP" name="otp">
                  <Input type="number" placeholder="OTP" />
                </Form.Item> */}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="4"
                      color="#1890FF"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loading}
                    />
                  </div>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      LOG IN
                    </Button>
                  </Form.Item>
                  <div
                    style={{
                      justifyContent: "right",
                      justify: "right",
                    }}
                  >
                    {/* {"Dont have an OTP?  "}
                  <Typography.Text
                    type="secondary"
                    onClick={handleGenerateOTPClick}
                    style={{
                      textAlign: "right",
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#1890ff",
                    }}
                    // Add your click handler function
                  >
                    Generate OTP
                  </Typography.Text> */}
                  </div>
                </Form>
              ) : (
                <Form
                  onFinish={onOtpFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item className="otp" label="OTP" name="otp">
                    <Input type="number" placeholder="OTP" />
                  </Form.Item>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ThreeDots
                      height="40"
                      width="40"
                      radius="4"
                      color="#1890FF"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={loading}
                    />
                  </div>

                  <Form.Item>
                    <p
                      style={{
                        justifyContent: "center",
                        justify: "center",
                        textAlign: "center",
                      }}
                    >
                      OTP sent to your registered phone number
                    </p>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      PROCEED
                    </Button>
                  </Form.Item>
                  <Typography.Text
                    type="secondary"
                    onClick={backToLogin}
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#1890ff",
                      textAlignLast: "center",
                    }}
                    // Add your click handler function
                  >
                    {"Back to Login"}
                  </Typography.Text>
                </Form>
              )}
            </Col>
          </Row>
          <ToastContainer />
        </Content>
      </Layout>
    </>
  );
};

export default Signin;
