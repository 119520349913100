import React, { useContext, useState } from "react";
import logo from "../assets/images/paysmart.png";
import { userContext } from "../context/UserContext";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import QRCode from "qrcode.react";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";

const Otp = () => {
  const { updateUser, person } = useContext(userContext);

  const [loading, setLoading] = useState(false);
  const [otpUrl, setOtpUrl] = useState("");
  const history = useHistory();

  const backToLogin = () => {
    history.push("/sign-in");
  };

  const onFinish = (values) => {
    setLoading(true);

    fetchQrCode(values);
  };

  const onFinishFailed = () => {};

  const fetchQrCode = async (values) => {
    axios
      .post(`${process.env.REACT_APP_ADMIN_URL}/auth/login`, {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        const { access_token } = response.data.data;

        axios
          .post(
            `${process.env.REACT_APP_ADMIN_URL}/users/me/tfa/generate`,
            { password: values.password },
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            setLoading(false);
            setOtpUrl(response.data.data.otpauth_url);
          })
          .catch((error) => {
            // Handle the error
            setLoading(false);
            toast.error("Invalid Credentials", {
              position: toast.POSITION.TOP_LEFT,
            });
          });
      })
      .catch((error) => {
        console.log(error);
        toast.error("An Error Occured", {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  const { Title } = Typography;
  const { Header, Content } = Layout;

  return (
    <>
      <Layout
        className="layout-default layout-signin"
        style={{ backgroundColor: "white", marginTop: "100px" }}
      >
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 2 }}
              md={{ span: 12 }}
            >
              <Title
                style={{ textAlign: "center" }}
                className="mb-15 font-regular text-muted"
                level={3}
              >
                QR Code
              </Title>
              <Title
                style={{ textAlign: "center" }}
                className="font-regular text-muted"
                level={5}
              >
                Enter your credentials to proceed
              </Title>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input type="email" placeholder="Email" />
                </Form.Item>
                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input type="password" placeholder="Password" />
                </Form.Item>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="4"
                    color="#1890FF"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={loading}
                  />
                </div>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    GET QR CODE
                  </Button>
                </Form.Item>
                <Typography.Text
                  type="secondary"
                  onClick={backToLogin}
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "#1890ff",
                    textAlignLast: "center",
                  }}
                  // Add your click handler function
                >
                  {"<-- Back to Login"}
                </Typography.Text>
              </Form>
            </Col>
            <Col
              className="sign-img"
              style={{
                padding: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              {otpUrl !== "" ? (
                <div style={{ textAlign: "center" }}>
                  <p>
                    Scan the QR code with your{" "}
                    <a
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                      target="_blank"
                    >
                      authenticator app
                    </a>{" "}
                    to get OTP.
                  </p>

                  <QRCode value={otpUrl} />
                </div>
              ) : (
                <div>
                  <h6>QR Code will appear here</h6>
                </div>
              )}
            </Col>
          </Row>
          <ToastContainer />
        </Content>
      </Layout>
    </>
  );
};

export default Otp;
