import React, { useContext, useState } from "react";
import logo from "../assets/images/paysmart.png";
import { userContext } from "../context/UserContext";
import loginImage from "../assets/images/login.png";
import liquidImage from "../assets/images/liquid.jpg";
import mtnImage from "../assets/images/mtn.png";
import zamtelImage from "../assets/images/zamtel.png";
import zescoImage from "../assets/images/zesco.jpeg";
import airtelImage from "../assets/images/airtel.jpg";
import airtelMoneyImage from "../assets/images/airtel_money.png";
import mtnMoneyImage from "../assets/images/mtn_money.png";
import zamtelMoneyImage from "../assets/images/zamtel_money.png";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { Layout, Button, Row, Col, Typography, Image } from "antd";

const Login = () => {
  const { updateUser, person } = useContext(userContext);

  const history = useHistory();

  const navigateToSignin = () => {
    history.push("/sign-in");
  };

  const { Title } = Typography;
  const { Header, Content } = Layout;

  return (
    <div style={{ backgroundColor: "white" }}>
      <Layout
        className="layout-default layout-signin"
        style={{ backgroundColor: "white" }}
      >
        <Content className="signin">
          <Row justify="center">
            <div style={{ position: "relative" }}>
              <Image
                src={loginImage}
                alt="Your Image Alt Text"
                preview={false}
                style={{
                  width: "100%",
                  borderRadius: "20px",
                }}
              />
              <Button
                type="primary"
                onClick={navigateToSignin}
                style={{
                  position: "absolute",
                  background: "#c40606",
                  borderColor: "#c40606",
                  bottom: "50px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  fontWeight: "bold",
                }}
              >
                Log In
              </Button>
            </div>
          </Row>
          <Row justify="center">
            <div style={{ textAlign: "center", height: "50%" }}>
              <img
                src={liquidImage}
                className="black-and-white-image"
                style={{ width: "34%", height: "auto", paddingTop: "18px" }}
              />
            </div>
            <div style={{ textAlign: "center", height: "50%" }}>
              <img
                src={airtelImage}
                className="black-and-white-image"
                style={{ width: "34%", height: "auto" }}
              />
            </div>
            <div style={{ textAlign: "center", height: "50%" }}>
              <img
                src={mtnImage}
                className="black-and-white-image"
                style={{ width: "34%", height: "auto", paddingTop: "10px" }}
              />
            </div>
            <div style={{ textAlign: "center", height: "50%" }}>
              <img
                src={zamtelImage}
                className="black-and-white-image"
                style={{ width: "34%", height: "auto", paddingTop: "12px" }}
              />
            </div>
          </Row>
          <Row justify="center">
            <div style={{ textAlign: "center", height: "50%" }}>
              <img
                src={zescoImage}
                className="black-and-white-image"
                style={{ width: "34%", height: "auto" }}
              />
            </div>
            <div style={{ textAlign: "center", height: "50%" }}>
              <img
                src={zamtelMoneyImage}
                className="black-and-white-image"
                style={{ width: "34%", height: "auto" }}
              />
            </div>
            <div style={{ textAlign: "center", height: "50%" }}>
              <img
                src={airtelMoneyImage}
                className="black-and-white-image"
                style={{ width: "34%", height: "auto", paddingTop: "12px" }}
              />
            </div>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};

export default Login;
