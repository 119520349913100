import Home from "../pages/home";
import Clients from "../pages/clients";
import Profile from "../pages/profile";
import Loans from "../pages/loans";
import Main from "./layout/Main";
import { Route } from "react-router-dom";
import Loandetails from "../pages/loandetails";
import Sms from "../pages/sms";
import React from "react";
import Collateral from "../pages/colateral";
import Transactions from "../pages/transactions";
import Vouchers from "../pages/vouchers";
import Zesco from "../pages/zesco";
import Talktime from "../pages/talktime";
import Deposits from "../pages/deposits";
import Adduser from "../pages/adduser";
import Topup from "../pages/topup";

const Master = () => {
  return (
    <Main>
      <Route exact path="/dashboard" component={Home} />
      <Route exact path="/clients" component={Clients} />
      <Route exact path="/loans" component={Loans} />
      <Route exact path="/transactions" component={""} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/reports" component={""} />
      <Route exact path="/bulksms" component={Profile} />
      <Route exact path="/loandetails" component={Loandetails} />
      <Route exact path="/sms" component={Sms} />
      <Route exact path="/talktime" component={Talktime} />
      <Route exact path="/zesco" component={Zesco} />
      <Route exact path="/deposits" component={Deposits} />
      <Route exact path="/collateral" component={Collateral} />
      <Route exact path="/transactions" component={Transactions} />
      <Route exact path="/adduser" component={Adduser} />
      <Route exact path="/topup" component={Topup} />
      <Route exact path="/vouchers/:provider" component={Vouchers} />
    </Main>
  );
};

export default Master;
