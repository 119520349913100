import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Select,
  Upload,
  message,
} from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Loader from "../components/loader";
import { useLocation, useHistory } from "react-router-dom";
import { FileExcelOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import Papa from "papaparse";

const Sms = () => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [bulkloading, setBulkLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const location = useLocation();
  const [file, setFile] = useState();
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [selectedPhone, setSelectedPhone] = useState("");
  const token = localStorage.getItem("access_token");
  const [message, setMessage] = useState("");
  const { Dragger } = Upload;

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleClientChange = (value) => {
    const client = clients.find(
      (client) => `${client.first_name} ${client.last_name}` === value
    );

    setSelectedPhone(client.phone); // Set the phone number based on the selected client
  };

  const getClients = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN_URL}/users?fields=id,first_name,last_name,phone`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      setClients(response.data.data);
    } catch (error) {
      if (error.response.status === 401) {
        history.push("/sign-in");
      }
    }
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");

      if (!isCSV) {
        message.error("You can only upload CSV files!");
      }

      return isCSV;
    },
    customRequest(info) {
      info.onSuccess();

      setFile(info.file);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        //message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onFinish = async (values) => {
    console.log(selectedPhone);

    const phone = `+26${selectedPhone}`;
    const message = values.message;

    try {
      setLoading(true);
      // Make a POST request using Axios
      await axios
        .post(`${process.env.REACT_APP_SERVER}/operation/singlesms`, {
          phone: phone,
          message: message,
          token: token,
        })
        .then((response) => {
          form.resetFields();
          setLoading(false);
          console.log(response);

          Swal.fire({
            title: "Message Sent",
            text: `${response.data.message}`,
            icon: "success",
          });
        });
    } catch (error) {
      setLoading(false);

      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  // const onBulkFinish = async (values) => {
  //   const numbers = clients.map((obj) => `+26${obj.phone}`);

  //   const postData = {
  //     message: values.message,
  //     phone: numbers,
  //     token: token,
  //   };

  //   try {
  //     setBulkLoading(true);
  //     // Make a POST request using Axios
  //     await axios
  //       .post(`${process.env.REACT_APP_SERVER}/operation/singlesms`, postData)
  //       .then((response) => {
  //         form.resetFields();
  //         setBulkLoading(false);

  //         Swal.fire({
  //           title: "Message Sent",
  //           text: `${response.data.message}`,
  //           icon: "success",
  //         });npm run bui
  //       });
  //   } catch (error) {
  //     setBulkLoading(false);

  //     console.error("Error submitting form:", error);
  //     // Handle error cases
  //   }
  // };

  const onFileUploaded = async (values) => {
    const fileContent = await readUploadedFile(file);

    // Parse CSV and extract phone numbers from the first column
    const numbers = parseCSV(fileContent);

    console.log("Extracted phone numbers:", numbers); // This should log the phone numbers

    const postData = {
      message: values.message,
      phone: numbers,
      token: token,
    };

    try {
      setBulkLoading(true);
      // Make a POST request using Axios
      await axios
        .post(`${process.env.REACT_APP_SERVER}/operation/singlesms`, postData)
        .then((response) => {
          form.resetFields();
          setBulkLoading(false);

          Swal.fire({
            title: "Message Sent",
            text: `${response.data.message}`,
            icon: "success",
          });
        });
    } catch (error) {
      setBulkLoading(false);

      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  const readUploadedFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result); // The file content
      };
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const parseCSV = (fileContent) => {
    const results = Papa.parse(fileContent, { header: false }); // No headers in the CSV file
    const rows = results.data; // Get the rows from the parsed CSV

    // Extract phone numbers from the first column and format them as +26{phone_number}
    const numbers = rows.map((row) => {
      const phoneNumber = row[0]; // First column
      return `+26${phoneNumber}`;
    });

    return numbers;
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Single SMS</Title>
            <Form name="myForm" onFinish={onFinish} form={form}>
              <Form.Item
                className="username"
                label="Client Name"
                name="client_name"
                rules={[
                  {
                    required: true,
                    message: "Please select a client",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Client"
                  onChange={handleClientChange}
                  loading={loading}
                  optionFilterProp="value" // Filter based on the 'value' prop of Option
                  filterOption={
                    (input, option) =>
                      option?.value.toLowerCase().includes(input.toLowerCase()) // Make sure 'value' is used for comparison
                  }
                >
                  {clients.map((client) => (
                    <Option
                      key={client.id}
                      value={`${client.first_name} ${client.last_name}`} // Using the full name in value
                    >
                      {client.first_name} {client.last_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                className="username"
                label="Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter Message",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Message" />
              </Form.Item>
              <Loader loading={loading} />
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Bulk SMS - Upload Numbers</Title>
            <Form name="myForm" onFinish={onFileUploaded}>
              <Form.Item className="username" label="Upload File">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <FileExcelOutlined style={{ color: "green" }} />
                  </p>

                  <p className="ant-upload-hint">File must be in CSV Format</p>
                </Dragger>
              </Form.Item>
              <Loader loading={bulkloading} />

              <Form.Item
                className="username"
                label="Type message here.."
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter Message",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Sms;
