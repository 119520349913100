import { Row, Col, Card, Typography, Form, Input, Button, Select } from "antd";
import axios from "axios";
import React, { useState, useContext } from "react";
import Loader from "../components/loader";
import { useLocation, useHistory } from "react-router-dom";
import { userContext } from "../context/UserContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FileExcelOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Swal from "sweetalert2";

const Talktime = () => {
  const { Title, Text } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [bulkloading, setBulkLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState("");
  const [provider, setProvider] = useState("");
  const [file, setFile] = useState();
  const location = useLocation();
  const history = useHistory();
  const { Option } = Select;
  const { setPerson, person, updateCgrate } = useContext(userContext);
  const token = localStorage.getItem("access_token");
  const { Dragger } = Upload;

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");

      if (!isCSV) {
        message.error("You can only upload CSV files!");
      }

      return isCSV;
    },
    customRequest(info) {
      info.onSuccess();

      setFile(info.file);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        //message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const handleProviderChange = (value) => {
    setProvider(value);
  };

  const handleAmountChange = (value) => {
    setAmount(value);
  };

  const onFileUploaded = async (values) => {
    const fileContent = await readUploadedFile(file);

    // Parse CSV and calculate sum
    const amounts = parseCSVAndSum(fileContent);
    const accounts = parseCSVAndCountRows(fileContent);

    Swal.fire({
      title: "Bulk Purchase",
      text: `Purchase K${amounts} total airtime for ${accounts} accounts?`,
      icon: "info",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        onBulkFinish(values, amounts);
      }
    });
  };

  const parseCSVAndSum = (csvContent) => {
    const rows = csvContent.split("\n");
    let sum = 0;

    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split(",");
      const amount = parseFloat(columns[2]);

      if (!isNaN(amount)) {
        sum += amount;
      }
    }

    return sum;
  };

  const parseCSVAndCountRows = (csvContent) => {
    const rows = csvContent.split("\n");
    let rowCount = 0;

    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split(",");

      // Check if the row has valid data, adjust this condition based on your CSV structure
      if (columns.length > 1 && columns[1].trim() !== "") {
        rowCount++;
      }
    }

    return rowCount;
  };

  const onBulkFinish = async (values, amounts) => {
    if (parseFloat(amounts) > parseFloat(person.balance)) {
      toast.error("Insufficient Funds", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      setBulkLoading(true);

      const formData = new FormData();

      formData.append("myfile", file);
      formData.append("companyname", person.companyname);
      formData.append("token", token);
      formData.append("balance_id", person.balance_id);
      formData.append("balance", person.balance);

      const balance = parseInt(person.balance) - parseInt(amounts);

      axios
        .post(
          `${process.env.REACT_APP_SERVER}/operation/bulktalktime`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          setBulkLoading(false);

          Swal.fire({
            title: response.data.message,
            text: `${response.data.processedCount} rows processed. Total : K${response.data.totalAmount}`,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              updateCgrate();
              setPerson({
                ...person,
                balance: balance,
              });

              history.push("/dashboard");
            }
          });
        })
        .catch((error) => {
          setBulkLoading(false);
          toast.error("Invalid Credentials", {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    }
  };

  const readUploadedFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const onFinish = async (values) => {
    if (parseFloat(values.amount) > parseFloat(person.balance)) {
      toast.error("Insufficient Funds", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      try {
        setLoading(true);

        const postData = {
          provider: values.provider,
          phone: values.phone,
          amount: values.amount,
          companyname: person.companyname,
          token: token,
          balance_id: person.balance_id,
          balance: person.balance,
          vtype: "Direct-Topup",
        };

        await axios
          .post(`${process.env.REACT_APP_SERVER}/operation/purchase`, postData)
          .then((response) => {
            form.resetFields();
            setLoading(false);
            setPerson({ ...person, balance: response.data.data.data.balance });
            updateCgrate();

            Swal.fire({
              title: "Direct-Topup",
              text: `Your Purchase was Successful`,
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/dashboard");
              }
            });
          });
      } catch (error) {
        setLoading(false);
        toast.error("Someting went wrong", {
          position: toast.POSITION.TOP_LEFT,
        });

        console.error("Error submitting form:", error);
        // Handle error cases
      }
    }
  };

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Single Number</Title>
            <Form name="myForm" onFinish={onFinish} form={form}>
              <Form.Item
                className="username"
                label="Mobile Operator"
                name="provider"
                rules={[
                  {
                    required: true,
                    message: "Please select mobile operator",
                  },
                ]}
              >
                <Select
                  placeholder="Select Mobile Operator"
                  onChange={handleProviderChange}
                >
                  <Option value="Airtel">Airtel</Option>
                  <Option value="MTN">MTN</Option>
                  <Option value="Zamtel">ZAMTEL</Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="username"
                label="Phone Number"
                name="phone"
                initialValue={
                  location.state && location.state.phone
                    ? location.state.phone
                    : ""
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                  ]}
                  type="number"
                  placeholder="Phone starting with 0"
                  onChange={handlePhoneChange}
                />
              </Form.Item>
              <Form.Item
                className="username"
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter amount",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter amount",
                    },
                  ]}
                  type="number"
                  placeholder="Amount"
                  onChange={handleAmountChange}
                />
              </Form.Item>
              <Loader loading={loading} />
              <Text
                type="secondary"
                style={{ marginBottom: "16px", display: "block" }}
              ></Text>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Bulk Talk-Time</Title>
            <Form name="myForm" onFinish={onFileUploaded}>
              <Form.Item className="username" label="Upload File">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <FileExcelOutlined style={{ color: "green" }} />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">File must be in CSV Format</p>
                </Dragger>
              </Form.Item>
              <Loader loading={bulkloading} />
              <Text
                type="secondary"
                style={{ marginBottom: "16px", display: "block" }}
              ></Text>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default Talktime;
