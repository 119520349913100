import { userContext } from "../../context/UserContext";
import axios from "axios";
import {
  Row,
  Col,
  Breadcrumb,
  Badge,
  Space,
  Dropdown,
  Button,
  List,
  Avatar,
} from "antd";
import { NavLink } from "react-router-dom";
import avtar from "../../assets/images/team-2.jpg";
import { useHistory } from "react-router-dom";
import {
  ClockCircleOutlined,
  WifiOutlined,
  ReloadOutlined,
  SyncOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import React, { useEffect, useContext, useState } from "react";

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

const bell = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
      fill="#111827"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#111827"
    ></path>
  </svg>,
];

const data = [
  {
    title: "New message from Sophie",
    description: <>{<ClockCircleOutlined />} 2 days ago</>,
    avatar: avtar,
  },
  {
    title: "New album by Travis Scott",
    description: <>{<ClockCircleOutlined />} 2 days ago</>,
    avatar: <Avatar shape="square" icon={<WifiOutlined />} />,
  },
  {
    title: "Payment completed",
    description: <>{<ClockCircleOutlined />} 2 days ago</>,
    avatar: <Avatar shape="square" icon={<CreditCardOutlined />} />,
  },
];

const Header = ({ name, subName, onPress }) => {
  const { person, setPerson } = useContext(userContext);
  const { branch } = useContext(userContext);
  const history = useHistory();
  const [spinning, setSpinning] = useState(false);

  const handleRefresh = () => {
    setSpinning(true);
    axios
      .get(`${process.env.REACT_APP_ADMIN_URL}/users/me?fields=*,balance.*`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(async (userResponse) => {
        setPerson({
          ...person,
          balance: userResponse.data.data.balance[0].balance,
        });

        setSpinning(false);
      })
      .catch((userError) => {
        console.log("Error fetching user data:", userError);
      });
  };

  const goToProfile = () => {
    const debtor = {
      ...person,
      balance: [
        {
          id: person.balance_id,
          balance: person.balance,
        },
      ],
    };

    history.push("/profile", { debtor });
  };

  useEffect(() => window.scrollTo(0, 0), []);

  const menu = (
    <List
      style={{ minWidth: "100%" }}
      className="header-notifications-dropdown"
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar shape="square" src={item.avatar} />}
            title={item.title}
            description={item.description}
          />
        </List.Item>
      )}
    />
  );

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
              {name.replace("/", "")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {subName.replace("/", "")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <div
            className="ant-page-header-heading"
            style={{
              cursor: "pointer",
              color: "#1890ff", // You can change the color to your preference
              textDecoration: "underline", // Add an underline for indication
            }}
          >
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {`${person.first_name} ${person.last_name}`}
            </span>
            <Space>
              <Badge
                overflowCount={9999}
                count={`k${parseFloat(person.balance).toFixed(2)}`}
                style={{
                  backgroundColor: "#4cc211",
                  fontWeight: "bold",
                }}
              />
            </Space>
            <Space>
              <SyncOutlined
                style={{
                  fontSize: "16px",
                  color: "#1890ff",
                  cursor: "pointer",
                  marginLeft: "8px",
                }}
                onClick={handleRefresh}
                spin={spinning}
              />
            </Space>
          </div>

          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          {/* <Link to="/sign-in" className="btn-sign-in">
            {profile}
            <span>Logout</span>
          </Link> */}
        </Col>
      </Row>
    </>
  );
};

export default Header;
