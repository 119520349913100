import { Row, Col, Card, Table, Input, Typography, Button, Avatar } from "antd";
import axios from "axios";
import React from "react";
import { useState, useEffect, useContext } from "react";
import { userContext } from "../context/UserContext";
import {
  FileExcelOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Loader from "../components/loader";
import airtelImage from "../assets/images/airtel.jpg";
import mtnImage from "../assets/images/mtn.png";
import zamtelImage from "../assets/images/zamtel.png";
import liquidImage from "../assets/images/liquid.png";
import dstvImage from "../assets/images/dstv.jpg";
import gotvImage from "../assets/images/gotv.jpg";
import zescoImage from "../assets/images/zesco.jpeg";
import { CSVLink } from "react-csv";

const Transactions = () => {
  const { Title } = Typography;
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const history = useHistory();
  const { person } = useContext(userContext);

  const handleReferenceClick = async (reference) => {
    try {
      // Make a request to your Express backend to get the CSV file
      const response = await axios.get(
        `${process.env.REACT_APP_ADMIN_URL}/${reference}`,
        {
          responseType: "blob", // Set the responseType to 'blob' to handle binary data
        }
      );

      // Create a Blob from the binary data
      const csvBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create a URL for the Blob
      const csvFileUrl = URL.createObjectURL(csvBlob);

      // Open the CSV file in a new tab or window
      window.open(csvFileUrl, "_blank");
    } catch (error) {
      console.error("Error downloading CSV file:", error.message);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  let url = "";

  if (person.role == "49d8994b-0c5c-4a41-b4cb-029773aa164b") {
    url = `${process.env.REACT_APP_ADMIN_URL}/items/transactions?fields=*,user_created.companyname&sort=-date_created&filter[user_created][companyname][_eq]=${person.companyname}`;
  } else {
    url = `${process.env.REACT_APP_ADMIN_URL}/items/transactions?fields=*,user_created.companyname&sort=-date_created`;
  }

  const getItems = async () => {
    try {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      });
      setLoading(false);

      setItems(response.data.data);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        history.push("/sign-in");
      }
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const formatdate = (inputDate) => {
    const date = new Date(inputDate);

    // Get day, month, and year components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
    const year = date.getFullYear();

    // Get hours, minutes, and seconds components
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Use string formatting to pad with leading zeros if needed
    const formattedDate = `${day.toString().padStart(2, "0")}-${month
      .toString()
      .padStart(2, "0")}-${year}`;

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    // Combine date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
  };

  const providerImages = {
    Airtel: airtelImage,
    MTN: mtnImage,
    Zamtel: zamtelImage,
    LiquidTelecom: liquidImage,
    Dstv: dstvImage,
    GOtv: gotvImage,
    Zesco: zescoImage,
  };

  const columns = [
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",

      render: (type, record) => (
        <div className="avatar-info">
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* {record.reference.startsWith("uploads/") ? (
              <FileExcelOutlined
                style={{
                  color: "green",
                  fontSize: "40px",
                  marginRight: "20px",
                }}
              />
            ) : (
              <Avatar
                className="shape-avatar"
                shape="square"
                size={40}
                src={providerImages[record.provider]}
              ></Avatar>
            )} */}

            <div>
              <Title level={5}>{type}</Title>
              {/* <Title level={5}>{record.user_created.companyname}</Title> */}
              <p>{formatdate(record.date_created)}</p>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) => (
        <>
          <div className="author-info">
            <p level={5}>K{parseFloat(amount).toFixed(2)}</p>
          </div>
        </>
      ),
    },
    {
      title: "BALANCE",
      dataIndex: "balance",
      key: "balance",
      render: (balance, record) => (
        <>
          <div className="author-info">
            <p level={5}>K{parseFloat(balance).toFixed(2)}</p>
          </div>
        </>
      ),
    },

    {
      title: "TRANSACTION",
      key: "transtype",
      dataIndex: "transtype",
      render: (transtype, record) => (
        <>
          <div className="author-info">
            <p level={5}>{transtype}</p>
          </div>
        </>
      ),
    },
    {
      title: "ACCOUNT",
      key: "phone",
      dataIndex: "phone",
      render: (phone, record) => (
        <>
          <div className="author-info">
            <p level={5}>{phone}</p>
          </div>
        </>
      ),
    },
    {
      title: "REF",
      key: "reference",
      dataIndex: "reference",
      render: (reference, record) => (
        <>
          <div className="author-info">
            {reference.startsWith("uploads/") ? (
              <Button
                type="link"
                onClick={() => handleReferenceClick(reference)}
                level={5}
              >
                {reference}
              </Button>
            ) : (
              <p level={5}>{reference}</p>
            )}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Transaction History"
                extra={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CSVLink
                      data={items}
                      filename={"transaction_history.csv"}
                      className="ant-btn"
                    >
                      <DownloadOutlined
                        style={{
                          fontSize: "24px",
                          marginTop: "4px",
                          marginLeft: "4px",
                        }}
                      />
                    </CSVLink>
                  </div>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={items}
                    className="ant-border-space"
                    pagination={{
                      pageSize: 10,
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Transactions;
